import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { IApprovers, IChangeRequest } from "../change-request/ChangeRequestModel";
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class ChangeRequestService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    public getUsersList() {
        return this.http.get(this.baseUrl + "account/getAll/Approver");
    }

    public getChangeRequestList(userFlag: string, filter: any) {
        let params = new HttpParams();
        for (const key in filter) {
            if (filter[key] !== null && filter[key] !== undefined) {
                params = params.set(key, filter[key]);
            }
        }
        return this.http.get(this.baseUrl + 'ChangeRequest' + userFlag,{params});
    }

    public getProjectList() {
        return this.http.get(this.baseUrl + 'Project');
    }

    public getChangePlantList() {
        return this.http.get(this.baseUrl + 'Plant');
    }

    public getDepartmentist() {
        return this.http.get(this.baseUrl + 'Department');
    }

    public getITOwnerList() {
        return this.http.get(this.baseUrl + 'ITOwner');
    }

    public getTechnologyList() {
        return this.http.get(this.baseUrl + 'Technology');
    }

    public getChangeRequestTypeList() {
        return this.http.get(this.baseUrl + 'TypeOfChange');
    }

    create(data: IChangeRequest): Observable<HttpResponse<IChangeRequest>> {
        return this.http.post<IChangeRequest>(this.baseUrl + 'ChangeRequest', data, { observe: 'response' });
    }

    update(data: IChangeRequest): Observable<HttpResponse<IChangeRequest>> {
        return this.http.put<IChangeRequest>(`${this.baseUrl}ChangeRequest/${data.id}`, data, { observe: 'response' });
    }

    updateApprover(data: IApprovers): Observable<HttpResponse<IApprovers>> {
        return this.http.put<IApprovers>(`${this.baseUrl}Approver/${data.id}/status`, data, { observe: 'response' });
    }
    public getChangeRequestById(id: string | null) {
        return this.http.get(`${this.baseUrl}ChangeRequest/id?id=${id}`)
    }

    saveFile(file:File): Observable<HttpResponse<any>> {
        let formdata = new FormData();
        formdata.append('file',file);
        return this.http.post<any>(`${this.baseUrl}File`,formdata, { observe: 'response' });
    }
}